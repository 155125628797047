import { css } from '@emotion/css';
import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import { GrafanaTheme2, IconName } from '@grafana/data';
import { IconButton, useStyles2 } from '@grafana/ui';

type ShortcutItem = {
  name: string;
  uid: string;
  tooltip: string;
  icon: IconName;
};

const items: ShortcutItem[] = [
  {
    name: 'home',
    uid: 'fleet',
    tooltip: 'Open fleet dashboard',
    icon: 'em-fleet',
  },
  {
    name: 'single-vessel',
    uid: 'vessel',
    tooltip: 'Open vessel dashboard',
    icon: 'em-ship',
  },
  {
    name: 'consumption',
    uid: 'consumption',
    tooltip: 'Open consumption dashboard',
    icon: 'signal',
  },
  {
    name: 'voyage-list',
    uid: 'voyage-list',
    tooltip: 'Open voyage list',
    icon: 'em-voyage',
  },
  {
    name: 'bunker-list',
    uid: 'bunker-list',
    tooltip: 'Open bunker list',
    icon: 'em-bunker',
  },
];

export const Shortcuts = React.memo(() => {
  const { push } = useHistory();
  const location = useLocation();

  const styles = useStyles2(getStyles);

  const getVariant = (uid: string) => {
    // check if the current path contains the uid using regex .../d/uid/...
    if (new RegExp(`\\/d\\/${uid}\\/`).test(location.pathname)) {
      return 'tertiary';
    }
    return undefined;
  };

  const renderItems = () => {
    return items.map((item) => {
      return (
        <IconButton
          tooltipPlacement="bottom"
          key={`button-${item.name}`}
          tooltip={item.tooltip}
          name={item.icon}
          size="xl"
          variant={getVariant(item.uid)}
          onClick={() => {
            push(`/d/${item.uid}/${item.name}`);
          }}
        />
      );
    });
  };

  return <div className={styles.wrapper}>{renderItems()}</div>;
});

Shortcuts.displayName = 'Shortcuts';

const getStyles = (theme: GrafanaTheme2) => ({
  wrapper: css({
    display: 'flex',
    gap: theme.spacing(2),
    justifyContent: 'center',
    width: '100%',
  }),
});
