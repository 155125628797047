import { css, cx } from '@emotion/css';
import React, { FC } from 'react';

import { colorManipulator } from '@grafana/data';
import { useTheme2 } from '@grafana/ui';

const GREEN = '#8CC645';
const WHITE = '#FFFFFF';
const GRAY = '#455C6E';

export interface BrandComponentProps {
  className?: string;
  children?: JSX.Element | JSX.Element[];
}

const Logo: FC<BrandComponentProps & { animate?: boolean }> = ({ className, animate }) => {
  const theme = useTheme2();
  return (
    <svg className={className} viewBox="0 0 393 394" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M304.5 347.9C302.9 347.6 301.2 347.9 299.8 348.9C269.3 369.6 233.6 380.6 196.6 380.6C95.4 380.5 13 298.1 13 196.9C13 155.2 27.4 114.4 53.5 81.9L54.6 80.5L56.3 81.1C58.7 81.9 61.2 82.4 63.7 82.4C76.2 82.4 86.3 72.2 86.3 59.8C86.3 47.3 76.1 37.2 63.7 37.2C51.2 37.2 41.1 47.4 41.1 59.8C41.1 63.8 42.2 67.7 44.2 71.2L45.1 72.7L44 74C16 108.7 0.600006 152.4 0.600006 197C0.600006 305.1 88.6 393.1 196.7 393.1C236.2 393.1 274.3 381.4 306.8 359.3C308.2 358.4 309.1 357 309.4 355.3C309.7 353.7 309.4 352 308.4 350.6C307.5 349.1 306.1 348.2 304.5 347.9ZM63.7 48.4C69.9 48.4 75 53.5 75 59.7C75 65.9 69.9 71 63.7 71C57.5 71 52.4 65.9 52.4 59.7C52.4 53.5 57.5 48.4 63.7 48.4Z"
        fill={theme.isDark ? WHITE : GRAY}
      />
      <path
        d="M364.7 313.5C364.7 310.1 363.9 306.8 362.5 303.7L361.9 302.5L362.6 301.3C382.3 270 392.8 233.9 392.8 196.8C392.8 88.7 304.8 0.699997 196.7 0.699997C163.3 0.699997 127 10.3 97.1 27.2C95.7 28 94.6 29.3 94.2 30.9C93.8 32.5 94 34.2 94.8 35.6C96.5 38.6 100.3 39.6 103.3 38C131.3 22.2 165.4 13.1 196.8 13.1C298.1 13.1 380.5 95.5 380.5 196.8C380.5 230.3 371.4 263.1 354.2 291.6L353.1 293.5L351.1 292.7C348.3 291.5 345.3 290.9 342.3 290.9C329.8 290.9 319.7 301.1 319.7 313.5C319.7 326 329.9 336.1 342.3 336.1C354.5 336.1 364.7 326 364.7 313.5ZM342 324.7C335.8 324.7 330.8 319.7 330.8 313.5C330.8 307.3 335.8 302.3 342 302.3C348.2 302.3 353.2 307.3 353.2 313.5C353.3 319.7 348.2 324.7 342 324.7Z"
        fill={theme.isDark ? WHITE : GRAY}
      />
      <path
        d="M206 326.5V183.5C206 137.2 243.5 99.7 289.8 99.7V242.8C289.8 289 252.3 326.5 206 326.5Z"
        fill={theme.isDark ? WHITE : GREEN}
      />
      <path
        d="M103 99.7V242.5C103 288.9 140.6 326.5 187 326.5H187.2V183.7C187.2 137.3 149.6 99.7 103.2 99.7H103Z"
        fill={theme.isDark ? WHITE : GREEN}
      />
      <path
        d="M266.4 300.9C266.4 300.9 231.8 342.6 163.9 324.9C133.4 316.9 108.1 287.4 104 256.9C102.1 242.5 102.3 227.7 110.9 214.6C131.4 183.3 183.1 190.1 188.2 238.6C191.2 266.9 198.8 297.8 266.4 300.9Z"
        fill={theme.isDark ? GREEN : GRAY}
      />
    </svg>
  );
};

export const LoginLogo: FC<BrandComponentProps & { logo?: string }> = ({ className }) => {
  return <Logo className={className} />;
};

const LoginBackground: FC<BrandComponentProps> = ({ className, children }) => {
  const theme = useTheme2();

  const background = css({
    '&:before': {
      content: '""',
      position: 'fixed',
      left: 0,
      right: 0,
      bottom: 0,
      top: 0,
      background: `url(public/img/EM_Cloud_BG.min.svg)`,
      backgroundPosition: 'top center',
      backgroundSize: 'auto',
      backgroundRepeat: 'no-repeat',

      opacity: 0,
      transition: 'opacity 3s ease-in-out',

      [theme.breakpoints.up('md')]: {
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      },
    },
  });

  return <div className={cx(background, className)}>{children}</div>;
};

const MenuLogo: FC<BrandComponentProps> = ({ className }) => {
  return <Logo className={className} />;
};

const LoginBoxBackground = () => {
  const theme = useTheme2();
  return css({
    background: colorManipulator.alpha(theme.colors.background.primary, 0.7),
    backgroundSize: 'cover',
  });
};

export class Branding {
  static LoginLogo = LoginLogo;
  static LoginBackground = LoginBackground;
  static MenuLogo = MenuLogo;
  static LoginBoxBackground = LoginBoxBackground;
  static AppTitle = 'EcoMATE Cloud';
  static LoginTitle = 'EcoMATE Cloud';
  static HideEdition = true;
  static GetLoginSubTitle = (): null | string => 'Measure the facts';
}
